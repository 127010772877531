import React, {useEffect} from "react"
import Layout from "../../../components/Layout/index"
import SEO from "../../../components/SEO/index"
import Title from "../../../components/Title/index"
import Button from "../../../components/Button/index"
import "./index.scss"
import Image from "../../../components/Image"
import injectComponents from "../../../helpers/injectReactComponents"
import {Link} from 'gatsby'
import {PostMeta} from "../../../components/PostMeta";
import Credits from "../../../components/Credits";
import DropDown from "../../../components/DroDown";
import SplashScreen from '../../../components/SplashScreen';
import {getImageNameId, IMAGE_SIZES, normalizeRelativeLink} from '../../../helpers/utils';
import Img from 'gatsby-image'
import _ from 'lodash'

let LinkToOtherPosts = ({title, question, postRef}) => {
  if (!postRef) {
    return null
  }
  let {hero, slug} = postRef;
  let fluid = _.get(hero, 'small.childImageSharp.fluid');

   return (
     <Link className={'LinkToOtherPosts'} to={`/work/${slug}`}>
        <Title.ExtraSmall>{title}</Title.ExtraSmall>
        <Title.Large>{question}</Title.Large>
         { fluid ? <Img fluid={fluid} className={'Image'}/> : <Image src={hero && hero.url}/>}
     </Link>
   )
};

let RedFooter = ({links}) => {

    return (
      <div className="RedFooter">
         <div>
            {
               links.map((link, index) => (<LinkToOtherPosts {...link} key={index.toString()}/>))
            }
         </div>
         <Button.HistoryBack/>
      </div>)
};

function fixSplashUrl(url) {
    if (!url) {
        return url
    }

    return url.replace('/images/', '/uploads/')
}

const WorksPost = ({pageContext}) => {
   let fluidMap = {};

   let {title, location, extractImages, filesMap, website, cost, dimensions, area, headline, size, typology, teamMembers, year, template, theme, hero, collaborators, relateds, splash, references} = pageContext;
   let postMeta = {
     location,
      typology,
      size,
      dimensions,
      year,
      area,
      website,
      constructionCost: cost,
   };

    ;(extractImages || []).forEach(data => {
        let fluid = _.get(data, 'childImageSharp.fluid');

        if (!fluid)return null;

        let src = getImageNameId(fluid.src);



        fluidMap[`${src}_${IMAGE_SIZES.MEDIUM}`] = fluid;
    });

    Object.keys(filesMap).forEach((hash) => {
        let value = fluidMap[`${filesMap[hash].replace(/\s/g, '%20')}_${IMAGE_SIZES.MEDIUM}`];
        fluidMap[`${hash}_${IMAGE_SIZES.MEDIUM}`] = value
    });


    useEffect(() => {
      let className = `${theme || 'creme'}`;

      document.body.classList.add(className);
      document.body.classList.add('theme');

      return () => document.body.classList.remove(className);
   }, [title]);

   return (
       <SplashScreen imageUrl={fixSplashUrl(normalizeRelativeLink(splash && splash.url))}>
          <Layout className='WorksPost'>
             <SEO title="Home" keywords={[]}/>

             <>
                <Title.Large className="GeneralTitle">{title}</Title.Large>
                <PostMeta meta={postMeta}/>

                 <Img fluid={hero && hero.large.childImageSharp.fluid} className="Hero ImageLarge Image"/>

                <Title.Large>{headline}</Title.Large>
                {injectComponents(template, {}, {references, collaborators, teamMembers, fluidMap})}
                 <DropDown header="Credits" showArrow={false} isOpen={true} className={'Credits-drop-down'}>
                    <Credits collaborators={collaborators} teamMembers={teamMembers}/>
                 </DropDown>
                <RedFooter links={relateds}/>
             </>
          </Layout>
       </SplashScreen>
   )
};

export default WorksPost
