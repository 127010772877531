import React, { Fragment } from "react"
import "./index.scss"
import Desc from "../Desc"
import { Link } from "gatsby"
import { replaceSpacesTo } from "../../helpers/textFormatter"


function CreditsUser({ fullName, isTeamMember, active }) {
   return (
      <div className="CreditsUser">
         <Desc.Normal children={
            <Link children={fullName}
               className={!active ? 'disabled' : ''}
               to={`/studio/${isTeamMember ? 'team-bios' : 'collaborators'}#${replaceSpacesTo(fullName)}`} />
         } />
      </div>
   )
}

function normalizeRole(role) {
   return role && role.toString().toUpperCase().replace(/__/g, ' / ').replace(/_/g, ' ');
}

export default function Credits({ teamMembers, collaborators }) {
   let role = '';
   return (
      <div className="Credits">

         {!!teamMembers.length && <Desc.Small children="ENVELOPE"
            className="envelope-title" />}

         <div>
            {
               teamMembers.sort(person => person.role).map((person, index) => {
                  let user = (
                     <Fragment key={index.toString()}>
                        {role !== person.role && <Desc.Small children={normalizeRole(person.role)} />}
                        <CreditsUser {...person} isTeamMember={true} />
                     </Fragment>
                  );

                  role = person.role;

                  return user;
               })
            }
         </div>

         {!!collaborators.length && <Desc.Small children="COLLABORATORS" />}


         <div className="collaborators-list">
            {
               collaborators.map((person, index) => <CreditsUser {...person} key={index.toString()} />)
            }
         </div>
      </div>
   )
}

