import React from 'react';
import './index.scss'
import {getWindow} from '../../helpers/utils';

const WAIT_TIME = 1500;

class SplashScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {loading: true};

        setTimeout(() => {
            this.setState({loading: false});

            if (props.once) {
                getWindow().sessionStorage.setItem(`splash_${props.imageUrl}`, 'once');
            }
        }, WAIT_TIME);
    }

    render() {
        let {loading} = this.state;
        let {children, imageUrl} = this.props;
        let isShow = !getWindow().sessionStorage.getItem(`splash_${imageUrl}`);

        return (
        <div className={`SplashWrap ${isShow ? 'showing' : 'hide'}`}>
            {children}
            {isShow && imageUrl &&
                <div className={`Splash ${loading ? 'show' : 'hide'}`}>
                    <img src={imageUrl} alt={'Splash'}/>
                </div>
            }
        </div>);
    }
}

SplashScreen.defaultProps = {
    once: false
};

export default SplashScreen;
